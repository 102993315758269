import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
`;

export const Icon = styled.div`
  border: 0.5rem solid #f18f01;
  border-radius: 100%;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 4rem;

  height: 128px;
  min-height: 128px;
  width: 128px;
  min-width: 128px;

  position: relative;
`;

export const Cross = styled.div`
  border: 0.25rem solid #f18f01;
  position: absolute;
  transform: rotate(45deg);
  transform-origin: 50% 50%;
  width: 100%;
`;
