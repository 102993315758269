import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { Button, Typography } from 'amphitheatre';
import {
  FaCreditCard,
  FaDatabase,
  FaFirefoxBrowser,
  FaChrome,
  FaEdge,
} from 'react-icons/fa';
import { ImSafari } from 'react-icons/im';
import { MdAccountBox } from 'react-icons/md';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from 'src/Layout';

import CallToAction from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import TAROTFeature from 'src/Components/TAROTFeature';
import SEO from 'src/Components/SEO';

import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';

const { Header } = Typography;

const Features = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: 768px) {
    margin-top: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Logos = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 3rem;
  text-align: center;

  svg:not(:first-child) {
    margin-left: 2rem;
  }

  @media only screen and (min-width: 768px) {
    font-size: 5rem;

    svg:not(:first-child) {
      margin-left: 5rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    font-size: 6rem;
  }
`;

const SatelliteText = styled(Base.Heading)`
  @media only screen and (min-width: 1024px) {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 1.25rem;
    transform: translate(-50%, -80%);
  }

  @media only screen and (min-width: 1200px) {
    font-size: 2rem;
    transform: translate(-50%, -50%);
  }
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  img {
    object-fit: cover;
    height: 296px;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;

    img {
      height: 512px;
    }
  }
`;

interface Props {
  site: {
    siteMetadata: {
      rawURL: string;
    };
  };
}

function TAROT({ site }: Props) {
  const { rawURL } = site.siteMetadata;

  return (
    <>
      <SEO
        title="T.A.R.O.T. | Saber Astronautics"
        description="T.A.R.O.T. is our free to use, browser based program observing the space environment through beautiful, accurate 3D renders."
        url="https://www.saberastro.com/tarot"
      />
      <Layout>
        <Hero.Wrapper>
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/tarot/Background.jpg"
            alt="T.A.R.O.T. background"
          />
          <Hero.Text>
            <Hero.MajorHeader>
              <Base.Yellow>T.A.R.O.T.</Base.Yellow>
            </Hero.MajorHeader>
            <Hero.Header style={{ marginBottom: '1rem' }}>
              Space visualisation within your browser
            </Hero.Header>
            <Hero.Header>
              <a
                href={`https://tarot.${rawURL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button size="xlg">Use this free tool</Button>
              </a>
            </Hero.Header>
          </Hero.Text>
        </Hero.Wrapper>
        <Container.Dark>
          <Base.Gap />
          <Base.Text>
            <Base.Paragraph>
              T.A.R.O.T. is an acronym for{' '}
              <Base.Yellow>
                Terrestrial and Astronomical Rapid Observation Toolkit
              </Base.Yellow>
              . A free program visualizing all objects in space including space
              weather, auroras and conjunctions. Wrap your mind around data
              creation and visualizing space phenomenon!
            </Base.Paragraph>
            <Base.Paragraph>
              Browser based so you can do it all from the comfort of your own
              home on your own device.
            </Base.Paragraph>
          </Base.Text>
          <Base.Gap />
          <StaticImage
            src="../images/tarot/intro.png"
            alt="T.A.R.O.T. interface"
          />
        </Container.Dark>
        <Container.Dark>
          <Logos>
            <FaFirefoxBrowser />
            <FaChrome />
            <ImSafari />
            <FaEdge />
          </Logos>
          <Header style={{ textAlign: 'center' }}>
            <Base.Yellow>Cross platform support</Base.Yellow> across major
            browsers
          </Header>
        </Container.Dark>
        <Container.Dark>
          <Features>
            <TAROTFeature icon={<MdAccountBox />} not>
              <b>NO</b> ACCOUNT NEEDED
            </TAROTFeature>
            <TAROTFeature icon={<FaCreditCard />} not>
              <b>NO</b> UPFRONT COST
            </TAROTFeature>
            <TAROTFeature icon={<FaDatabase />}>
              <b>FREE ACCESS TO DATA</b>
            </TAROTFeature>
          </Features>
        </Container.Dark>
        <Container.Dark
          style={{ backgroundColor: 'black', position: 'relative' }}
        >
          <StaticImage
            src="../images/tarot/Satellite.jpg"
            alt="Satellite and live data"
          />
          <SatelliteText>
            Accurate live data for satellites & payloads
          </SatelliteText>
        </Container.Dark>
        <Gallery>
          <StaticImage
            src="../images/tarot/debris.png"
            alt="First T.A.R.O.T. showcase"
          />
          <StaticImage
            src="../images/tarot/fov.png"
            alt="Second T.A.R.O.T. showcase"
          />
          <StaticImage
            src="../images/tarot/satellite.png"
            alt="Third T.A.R.O.T. showcase"
          />
          <StaticImage
            src="../images/tarot/sunspots.jpg"
            alt="Fourth T.A.R.O.T. showcase"
          />
        </Gallery>
        <Container.Dark>
          <Base.Text>
            <Header>
              <Base.Yellow>Frequently Asked Questions</Base.Yellow>
            </Header>
            <Header level={3}>Why should I make an account?</Header>
            <Base.Paragraph>
              T.A.R.O.T. was built to constantly stream and process complex
              data, making an account allows us to push through more data to
              your browser to provide you with the best experience.
            </Base.Paragraph>
            <br />
            <Header level={3}>
              T.A.R.O.T. is slow or not running properly, what should I do?
            </Header>
            <Base.Paragraph>
              Check your data connection is stable. Ensure your browser and
              hardware is up to technical specifications. Otherwise feel free to
              email us at feedback@saberastro.com and provide as much
              information as possible alongside screenshots or recordings of the
              issue.
            </Base.Paragraph>
          </Base.Text>
        </Container.Dark>
        <CallToAction
          header="Want access to this powerful tool?"
          linkText="Get started now!"
          to={`https://tarot.${rawURL}`}
          external
        />
      </Layout>
    </>
  );
}

export default function (): JSX.Element {
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              rawURL
            }
          }
        }
      `}
      render={({ site }) => <TAROT site={site} />}
    />
  );
}
