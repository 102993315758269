import React from 'react';
import { Typography } from 'amphitheatre';
import { Cross, Icon, Wrapper } from './TAROTFeature.styled';

const { Header } = Typography;

interface Props {
  children: React.ReactNode;
  icon: React.ReactNode;
  not?: boolean;
}

const TAROTFeature: React.FunctionComponent<Props> = ({
  children,
  icon,
  not,
}) => {
  return (
    <Wrapper>
      <Icon>
        {not ? <Cross /> : null}
        {icon}
      </Icon>
      <Header level={5}>{children}</Header>
    </Wrapper>
  );
};

export default TAROTFeature;
